import Vue from 'vue';

Vue.mixin({
  methods: {
    convertToKm(value) {
      const kms = value / 1000;
      // return kms;
      return `${kms.toLocaleString('fr-FR', { maximumFractionDigits: 1 })} km`;
    },
    getMetric(code, attr = 'value', metrics = this.metrics) {
      const metric = metrics.find((element) => element.code === code);
      return metric ? metric[attr] : null;
    },
    diffMetric(code) {
      const metric = this.metrics.find((element) => element.code === code);
      return metric ? (metric.avg_value - metric.value) / metric.avg_value : null;
    },
    formatMetric(code, attr = 'value', metrics = this.metrics) {
      return new Intl.NumberFormat('fr-FR').format(this.getMetric(code, attr, metrics));
    },
    formatProg(code, attr = 'value', metrics = this.metrics) {
      return `${(this.getMetric(code, attr, metrics) >= 0 ? '+' : '') + Intl.NumberFormat('fr-FR').format(this.getMetric(code, attr, metrics))} %`;
    },
    formatNumericValue(value) {
      return new Intl.NumberFormat('fr-FR').format(value);
    },
    capitalize(s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    formatPlural(amount, singular, plural = null) {
      if (plural === null) {
        plural = `${singular}s`;
      }
      return (amount > 1) ? plural : singular;
    },
    theGoodArticle(word, article1, article2) {
      return /[aeiouyAEIOUY]/.test(word.charAt(0)) ? article2 : article1;
    },
    isEMail: (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
    roundToOne(num) {
      return new Intl.NumberFormat('fr-FR').format(+(`${Math.round(`${num}e+1`)}e-1`));
    },
    async logout() {
      try {
        await this.$auth.logout();
      } catch (error) {
        //
      }
    },
  },
});
