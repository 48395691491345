import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _edc9da10 = () => interopDefault(import('../pages/regions/_region/departements/_departement.vue' /* webpackChunkName: "" */))
const _c011d7ca = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _29077de0 = () => interopDefault(import('../pages/coverbo/index.vue' /* webpackChunkName: "pages/coverbo/index" */))
const _43bdd19c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _315bc42c = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _59481af5 = () => interopDefault(import('../pages/post-avis.vue' /* webpackChunkName: "pages/post-avis" */))
const _4426b09f = () => interopDefault(import('../pages/prix-immobilier/index.vue' /* webpackChunkName: "pages/prix-immobilier/index" */))
const _37adb584 = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _445af350 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _0c55e1a2 = () => interopDefault(import('../pages/password/email.vue' /* webpackChunkName: "pages/password/email" */))
const _76b55ff7 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _837c9f28 = () => interopDefault(import('../pages/classements/_classement.vue' /* webpackChunkName: "pages/classements/_classement" */))
const _d1f471a2 = () => interopDefault(import('../pages/intercommunalites/_epci.vue' /* webpackChunkName: "pages/intercommunalites/_epci" */))
const _42700f57 = () => interopDefault(import('../pages/prix-immobilier/_slug.vue' /* webpackChunkName: "pages/prix-immobilier/_slug" */))
const _3d96f90f = () => interopDefault(import('../pages/regions/_region/index.vue' /* webpackChunkName: "pages/regions/_region/index" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _eb2c2454 = () => interopDefault(import('../pages/_city/index.vue' /* webpackChunkName: "pages/_city/index" */))
const _6df29569 = () => interopDefault(import('../pages/_city/compare.vue' /* webpackChunkName: "pages/_city/compare" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/regions/la-reunion/",
    component: _edc9da10,
    name: "regions-la-reunion"
  }, {
    path: "/regions/martinique/",
    component: _edc9da10,
    name: "regions-martinique"
  }, {
    path: "/regions/guadeloupe/",
    component: _edc9da10,
    name: "regions-guadeloupe"
  }, {
    path: "/regions/guyane/",
    component: _edc9da10,
    name: "regions-guyane"
  }, {
    path: "/regions/mayotte/",
    component: _edc9da10,
    name: "regions-mayotte"
  }, {
    path: "/confidentialite/",
    component: _c011d7ca,
    pathToRegexpOptions: {"strict":true},
    name: "confidentialite"
  }, {
    path: "/coverbo/",
    component: _29077de0,
    pathToRegexpOptions: {"strict":true},
    name: "coverbo"
  }, {
    path: "/login/",
    component: _43bdd19c,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/mentions-legales/",
    component: _315bc42c,
    pathToRegexpOptions: {"strict":true},
    name: "mentions-legales"
  }, {
    path: "/post-avis/",
    component: _59481af5,
    pathToRegexpOptions: {"strict":true},
    name: "post-avis"
  }, {
    path: "/prix-immobilier/",
    component: _4426b09f,
    pathToRegexpOptions: {"strict":true},
    name: "prix-immobilier"
  }, {
    path: "/account/favorites/",
    component: _37adb584,
    pathToRegexpOptions: {"strict":true},
    name: "account-favorites"
  }, {
    path: "/account/profile/",
    component: _445af350,
    pathToRegexpOptions: {"strict":true},
    name: "account-profile"
  }, {
    path: "/password/email/",
    component: _0c55e1a2,
    pathToRegexpOptions: {"strict":true},
    name: "password-email"
  }, {
    path: "/password/reset/:token?/",
    component: _76b55ff7,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset-token"
  }, {
    path: "/classements/:classement?/",
    component: _837c9f28,
    pathToRegexpOptions: {"strict":true},
    name: "classements-classement"
  }, {
    path: "/intercommunalites/:epci?/",
    component: _d1f471a2,
    pathToRegexpOptions: {"strict":true},
    name: "intercommunalites-epci"
  }, {
    path: "/prix-immobilier/:slug?/",
    component: _42700f57,
    pathToRegexpOptions: {"strict":true},
    name: "prix-immobilier-slug"
  }, {
    path: "/regions/:region/",
    component: _3d96f90f,
    pathToRegexpOptions: {"strict":true},
    name: "regions-region"
  }, {
    path: "/regions/:region?/departements/:departement?/",
    component: _edc9da10,
    pathToRegexpOptions: {"strict":true},
    name: "regions-region-departements-departement"
  }, {
    path: "/",
    component: _1479741b,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:city/",
    component: _eb2c2454,
    pathToRegexpOptions: {"strict":true},
    name: "city"
  }, {
    path: "/:city/compare/",
    component: _6df29569,
    pathToRegexpOptions: {"strict":true},
    name: "city-compare"
  }, {
    path: "/classements/:classement?/page/:pagenumber?/",
    component: _837c9f28,
    pathToRegexpOptions: {"strict":true},
    name: "classements-classement-pagine"
  }, {
    path: "/classements/:classement?/:filter?/",
    component: _837c9f28,
    pathToRegexpOptions: {"strict":true},
    name: "classements-classement-filter"
  }, {
    path: "/classements/:classement?/",
    component: _837c9f28,
    pathToRegexpOptions: {"strict":true},
    name: "classements-classement-default-filter"
  }, {
    path: "/classements/:classement?/:filter?/page/:pagenumber?/",
    component: _837c9f28,
    pathToRegexpOptions: {"strict":true},
    name: "classements-classement-filter-pagine"
  }, {
    path: "/vs/:city1?/:city2?/",
    component: _6df29569,
    pathToRegexpOptions: {"strict":true},
    name: "compare-cities"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
