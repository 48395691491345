




export default {

    data() {
        return {
            show: false,
        };
    },
    created() {
        this.$nuxt.$on("open-forgotpwd-modal", data => {
            this.show = true;
        });
    },
    beforeDestroy () {
      this.$nuxt.$off('open-forgotpwd-modal');
    },
    methods: {

        close() {
            this.show = false;

        },
        goBackToLoginModal() {
            this.close();
            this.$nuxt.$emit("open-login-modal",{mode : 'login'});
        }
    },
};
