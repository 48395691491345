import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faThermometerHalf as freeFasFaThermometerHalf,
  faTemperatureHigh as freeFasFaTemperatureHigh,
  faTemperatureLow as freeFasFaTemperatureLow,
  faComment as freeFasFaComment,
  faSun as freeFasFaSun,
  faCloudRain as freeFasFaCloudRain,
  faSnowflake as freeFasFaSnowflake,
  faMoneyBillWave as freeFasFaMoneyBillWave,
  faBriefcase as freeFasFaBriefcase,
  faStar as freeFasFaStar,
  faStarHalfAlt as freeFasFaStarHalfAlt,
  faKiwiBird as freeFasFaKiwiBird,
  faPhone as freeFasFaPhone,
  faWifi as freeFasFaWifi,
  faCity as freeFasFaCity,
  faShoppingCart as freeFasFaShoppingCart,
  faUtensils as freeFasFaUtensils,
  faPlaneDeparture as freeFasFaPlaneDeparture,
  faSchool as freeFasFaSchool,
  faClinicMedical as freeFasFaClinicMedical,
  faHome as freeFasFaHome,
  faBuilding as freeFasFaBuilding,
  faArrowCircleUp as freeFasFaArrowCircleUp,
  faArrowCircleDown as freeFasFaArrowCircleDown,
  faFlag as freeFasFaFlag,
  faChevronDown as freeFasFaChevronDown,
  faChevronUp as freeFasFaChevronUp,
  faSearch as freeFasFaSearch,
  faBars as freeFasFaBars,
  faPen as freeFasFaPen,
  faCaretUp as freeFasFaCaretUp,
  faCaretDown as freeFasFaCaretDown,
  faEnvelope as freeFasFaEnvelope,
  faQuestionCircle as freeFasFaQuestionCircle,
  faThumbsUp as freeFasFaThumbsUp,
  faThumbsDown as freeFasFaThumbsDown,
  faArrowDown as freeFasFaArrowDown,
  faSignOutAlt as freeFasFaSignOutAlt,
  faUserCircle as freeFasFaUserCircle,
  faCheck as freeFasFaCheck,
  faHeart as freeFasFaHeart,
  faUsers as freeFasFaUsers,
  faCloudSun as freeFasFaCloudSun,
  faEye as freeFasFaEye,
  faMarker as freeFasFaMarker,
  faArrowRight as freeFasFaArrowRight,
  faEyeSlash as freeFasFaEyeSlash,
  faSlidersH as freeFasFaSlidersH
} from '@fortawesome/free-solid-svg-icons'

import {
  faStar as freeFarFaStar,
  faHeart as freeFarFaHeart,
  faComment as freeFarFaComment
} from '@fortawesome/free-regular-svg-icons'

library.add(
  freeFasFaThermometerHalf,
  freeFasFaTemperatureHigh,
  freeFasFaTemperatureLow,
  freeFasFaComment,
  freeFasFaSun,
  freeFasFaCloudRain,
  freeFasFaSnowflake,
  freeFasFaMoneyBillWave,
  freeFasFaBriefcase,
  freeFasFaStar,
  freeFasFaStarHalfAlt,
  freeFasFaKiwiBird,
  freeFasFaPhone,
  freeFasFaWifi,
  freeFasFaCity,
  freeFasFaShoppingCart,
  freeFasFaUtensils,
  freeFasFaPlaneDeparture,
  freeFasFaSchool,
  freeFasFaClinicMedical,
  freeFasFaHome,
  freeFasFaBuilding,
  freeFasFaArrowCircleUp,
  freeFasFaArrowCircleDown,
  freeFasFaFlag,
  freeFasFaChevronDown,
  freeFasFaChevronUp,
  freeFasFaSearch,
  freeFasFaBars,
  freeFasFaPen,
  freeFasFaCaretUp,
  freeFasFaCaretDown,
  freeFasFaEnvelope,
  freeFasFaQuestionCircle,
  freeFasFaThumbsUp,
  freeFasFaThumbsDown,
  freeFasFaArrowDown,
  freeFasFaSignOutAlt,
  freeFasFaUserCircle,
  freeFasFaCheck,
  freeFasFaHeart,
  freeFasFaUsers,
  freeFasFaCloudSun,
  freeFasFaEye,
  freeFasFaMarker,
  freeFasFaArrowRight,
  freeFasFaEyeSlash,
  freeFasFaSlidersH,
  freeFarFaStar,
  freeFarFaHeart,
  freeFarFaComment
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
