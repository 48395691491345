
export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default() {
                return `text-input-${this._uid}`
            },
        },
        type: {
            type: String,
            default: 'text',
        },
        value: String,
        label: String,
        error: Array,
    },
    data: function() {
        return {
            displayError:true,
            showEye:true
        }
    },
    methods: {
        focus() {
            this.displayError=false;
            this.$refs.input.focus()
        },
        select() {
            this.$refs.input.select()
        },
        setSelectionRange(start, end) {
            this.$refs.input.setSelectionRange(start, end)
        },
        toogleEye() {
            this.showEye = !this.showEye;
            this.$refs.input.type = (this.showEye) ? 'password' : 'text';
        }
    },
    watch: {
        error: function() {
            this.displayError = true;
        }
    }
}
