const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['headers'] = require('../middleware/headers.js')
middleware['headers'] = middleware['headers'].default || middleware['headers']

export default middleware
