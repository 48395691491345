


export default {

    data() {
        return {
            form: {
                password: null,
                email: null,
                user_name:null
            },
            errors: {},
        }
    },
    methods: {
        async registerUser() {
            const isValid = await this.$refs.registerForm.validate();
            if (isValid) {
                this.$axios.$post(`/api/register/`, this.form)
                    .then((response) => {

                        this.$auth.loginWith('laravelSanctum', {
                            data: {
                                email: this.form.email,
                                password: this.form.password
                            }
                        })
                            .then((response) => {
                                this.$emit('logged', 'true');
                            });
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 422) {
                            this.$refs.registerForm.setErrors(error.response.data.errors);
                        } else {
                            //alert('Une erreur est survenue, veuillez réessayer ultérieurement.');
                        }
                    })
            }
        },
    }
}
