

export default {
    head() {
        return {
            meta: [
                {
                    hid: 'twitter:url',
                    name: 'twitter:url',
                    content: 'https://www.villesavivre.fr' + this.$route.path
                },
                {
                    hid: 'og:url',
                    property: 'og:url',
                    content: 'https://www.villesavivre.fr' + this.$route.path
                },
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://www.villesavivre.fr' + this.$route.path
                }
            ]
        }
    },
}
