



export default {
    data() {
        return {
            form: {
                login: null,
                email: null
            },
            errors: {}
        }
    },

    methods: {
        async loginUser() {
            const isValid = await this.$refs.loginForm.validate();
            if (isValid) {

                this.$auth.loginWith('laravelSanctum', {
                    data: this.form
                })
                    .then(() => {
                        this.$emit('logged', 'true');
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            this.$refs.loginForm.setErrors({ 'email': ['Email ou mot de passe incorrect'] })
                        } else {
                            //alert('Une erreur est survenue, veuillez réessayer ultérieurement.');
                        }
                    })
            }
        },
        showForgotPasswordModal() {
            this.$nuxt.$emit("close-login-modal");
            this.$nuxt.$emit("open-forgotpwd-modal");
        }
    }
}
