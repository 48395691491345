
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

export default {
  data() {
    return {
      searchClient: this.getSearchClient(),
      current: -1,
      counter: 0,
      query: '',
      isOverlay: false,
    };
  },
  props: {
    redirect: {
      type: Boolean,
      default: true,
    },
  },
  created() { },
  methods: {
    //Up pressed in suggestions
    up(event) {
      event.preventDefault();
      if (this.current > -1) {
        this.current--;
      }
      this.updateSearchField();
    },
    //Down pressed in suggestions
    down() {
      const count = document.getElementsByClassName(
        'autocomplete__results--item'
      ).length;
      if (this.current < count - 1) {
        this.current++;
        this.updateSearchField();
      }
    },
    //Highlighting element
    isActive(idx) {
      return idx === this.current;
    },
    //User changes input
    change() {
      this.isOverlay = true;
      this.query = document.getElementById('search-field').value;
      this.current = -1;
    },
    // Enter on highlight element
    enter() {
      const elmt = document.querySelector(
        '[data-link-idx="' + this.current + '"]'
      );
      if (elmt) {
        const to = elmt.getAttribute('data-slug');
        this.query = elmt.getAttribute('data-formatted-query');

        const hit = {
          slug: to,
        };
        this.$emit('search-selected', hit);
        this.isOverlay = false;
        if (this.redirect == true) {
          this.$router.push(`/${to}/`);
        }
      } else {
        // TODO redirect vers page search
      }
    },
    // Click on highlight element
    click(hit) {
      if (hit.slug) {
        this.query = hit.zipcode
          ? `${hit.full_name} (${hit.zipcode})`
          : `${hit.full_name}`;
        this.isOverlay = false;
        this.$emit('search-selected', hit);
        if (this.redirect == true) {
          this.$router.push(`/${hit.slug}/`);
        }
      }
    },
    updateSearchField() {
      if (this.current >= 0) {
        const value = document
          .querySelector('[data-value-idx="' + this.current + '"]')
          .innerText.trim();
        document.getElementById('search-field').value = value;
      } else {
        document.getElementById('search-field').value = this.query;
      }
    },
    getSearchClient() {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: this.$config.typesenseKey,
          nodes: [
            {
              host: this.$config.typesenseHost,
              port: this.$config.typesensePort,
              protocol: this.$config.typesenseProtocol,
            },
          ],
          cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
        // The following parameters are directly passed to Typesense's search API endpoint.
        //  So you can pass any parameters supported by the search endpoint below.
        //  query_by is required.
        additionalSearchParameters: {
          query_by: 'full_name,zipcode',
          sort_by: 'population:desc'
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    },
    hide: function () {
      this.isOverlay = false;
      this.$emit('update:search-button', false);
    },
    show: function () {
      this.isOverlay = true;
    },
  },
};
