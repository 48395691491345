



export default {

    data() {
        return {
            show: false,
            mode: 'login',
            next: null,
            props: null
        };
    },
    created() {
        this.$nuxt.$on("open-login-modal", data => {
            this.show = true;
            this.mode = (data && data.mode) ? data.mode : 'login';
            this.next = (data && data.next) ? data.next : null;
            this.props = (data && data.props) ? data.props : null;
        });
        this.$nuxt.$on("close-login-modal", data => {
            this.show = false;
        });
    },
    beforeDestroy () {
      this.$nuxt.$off('open-login-modal');
      this.$nuxt.$off('close-login-modal');
    },
    methods: {

        close() {
            this.show = false;
        },
        logged() {
            this.close();
            this.$nuxt.$emit("successfully-logged", { next: this.next, props: this.props});
        },
        registerMode() {
            this.mode = 'register';
        },
        loginMode() {
            this.mode = 'login';
        },
    },
};
