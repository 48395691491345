export const BaseBarHorizontalComponent = () => import('../../components/BaseBarHorizontalComponent.vue' /* webpackChunkName: "components/base-bar-horizontal-component" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadCrumbs = () => import('../../components/BaseBreadCrumbs.vue' /* webpackChunkName: "components/base-bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdownMenu = () => import('../../components/BaseDropdownMenu.vue' /* webpackChunkName: "components/base-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseElectionResultCircle = () => import('../../components/BaseElectionResultCircle.vue' /* webpackChunkName: "components/base-election-result-circle" */).then(c => wrapFunctional(c.default || c))
export const BaseModalComponent = () => import('../../components/BaseModalComponent.vue' /* webpackChunkName: "components/base-modal-component" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/BasePagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BasePercentageCircle = () => import('../../components/BasePercentageCircle.vue' /* webpackChunkName: "components/base-percentage-circle" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeInput = () => import('../../components/BaseRangeInput.vue' /* webpackChunkName: "components/base-range-input" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingStars = () => import('../../components/BaseRatingStars.vue' /* webpackChunkName: "components/base-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const BaseRawHtml = () => import('../../components/BaseRawHtml.vue' /* webpackChunkName: "components/base-raw-html" */).then(c => wrapFunctional(c.default || c))
export const BaseScore = () => import('../../components/BaseScore.vue' /* webpackChunkName: "components/base-score" */).then(c => wrapFunctional(c.default || c))
export const BaseSelectInput = () => import('../../components/BaseSelectInput.vue' /* webpackChunkName: "components/base-select-input" */).then(c => wrapFunctional(c.default || c))
export const BaseSimpleLineChart = () => import('../../components/BaseSimpleLineChart.vue' /* webpackChunkName: "components/base-simple-line-chart" */).then(c => wrapFunctional(c.default || c))
export const BaseSimplePieChart = () => import('../../components/BaseSimplePieChart.vue' /* webpackChunkName: "components/base-simple-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../components/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseSpinnerComponent = () => import('../../components/BaseSpinnerComponent.vue' /* webpackChunkName: "components/base-spinner-component" */).then(c => wrapFunctional(c.default || c))
export const BaseStars = () => import('../../components/BaseStars.vue' /* webpackChunkName: "components/base-stars" */).then(c => wrapFunctional(c.default || c))
export const BaseTextInput = () => import('../../components/BaseTextInput.vue' /* webpackChunkName: "components/base-text-input" */).then(c => wrapFunctional(c.default || c))
export const BaseTextareaInput = () => import('../../components/BaseTextareaInput.vue' /* webpackChunkName: "components/base-textarea-input" */).then(c => wrapFunctional(c.default || c))
export const BigFranceMap = () => import('../../components/BigFranceMap.vue' /* webpackChunkName: "components/big-france-map" */).then(c => wrapFunctional(c.default || c))
export const CardCityHall = () => import('../../components/CardCityHall.vue' /* webpackChunkName: "components/card-city-hall" */).then(c => wrapFunctional(c.default || c))
export const CardComments = () => import('../../components/CardComments.vue' /* webpackChunkName: "components/card-comments" */).then(c => wrapFunctional(c.default || c))
export const CardCompare = () => import('../../components/CardCompare.vue' /* webpackChunkName: "components/card-compare" */).then(c => wrapFunctional(c.default || c))
export const CardDistricts = () => import('../../components/CardDistricts.vue' /* webpackChunkName: "components/card-districts" */).then(c => wrapFunctional(c.default || c))
export const CardEconomy = () => import('../../components/CardEconomy.vue' /* webpackChunkName: "components/card-economy" */).then(c => wrapFunctional(c.default || c))
export const CardLabels = () => import('../../components/CardLabels.vue' /* webpackChunkName: "components/card-labels" */).then(c => wrapFunctional(c.default || c))
export const CardNearCities = () => import('../../components/CardNearCities.vue' /* webpackChunkName: "components/card-near-cities" */).then(c => wrapFunctional(c.default || c))
export const CardPolitical = () => import('../../components/CardPolitical.vue' /* webpackChunkName: "components/card-political" */).then(c => wrapFunctional(c.default || c))
export const CardPopulation = () => import('../../components/CardPopulation.vue' /* webpackChunkName: "components/card-population" */).then(c => wrapFunctional(c.default || c))
export const CardRealEstate = () => import('../../components/CardRealEstate.vue' /* webpackChunkName: "components/card-real-estate" */).then(c => wrapFunctional(c.default || c))
export const CardReviews = () => import('../../components/CardReviews.vue' /* webpackChunkName: "components/card-reviews" */).then(c => wrapFunctional(c.default || c))
export const CardSecurity = () => import('../../components/CardSecurity.vue' /* webpackChunkName: "components/card-security" */).then(c => wrapFunctional(c.default || c))
export const CardServices = () => import('../../components/CardServices.vue' /* webpackChunkName: "components/card-services" */).then(c => wrapFunctional(c.default || c))
export const CardSummary = () => import('../../components/CardSummary.vue' /* webpackChunkName: "components/card-summary" */).then(c => wrapFunctional(c.default || c))
export const CardWeather = () => import('../../components/CardWeather.vue' /* webpackChunkName: "components/card-weather" */).then(c => wrapFunctional(c.default || c))
export const CityMapComponent = () => import('../../components/CityMapComponent.vue' /* webpackChunkName: "components/city-map-component" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormComment = () => import('../../components/FormComment.vue' /* webpackChunkName: "components/form-comment" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/FormLogin.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormMailPassword = () => import('../../components/FormMailPassword.vue' /* webpackChunkName: "components/form-mail-password" */).then(c => wrapFunctional(c.default || c))
export const FormProfile = () => import('../../components/FormProfile.vue' /* webpackChunkName: "components/form-profile" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/FormRegister.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../components/FormResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormReview = () => import('../../components/FormReview.vue' /* webpackChunkName: "components/form-review" */).then(c => wrapFunctional(c.default || c))
export const FormSocialLogin = () => import('../../components/FormSocialLogin.vue' /* webpackChunkName: "components/form-social-login" */).then(c => wrapFunctional(c.default || c))
export const GoogleAds = () => import('../../components/GoogleAds.vue' /* webpackChunkName: "components/google-ads" */).then(c => wrapFunctional(c.default || c))
export const HeaderCity = () => import('../../components/HeaderCity.vue' /* webpackChunkName: "components/header-city" */).then(c => wrapFunctional(c.default || c))
export const HeaderEpci = () => import('../../components/HeaderEpci.vue' /* webpackChunkName: "components/header-epci" */).then(c => wrapFunctional(c.default || c))
export const HeaderHome = () => import('../../components/HeaderHome.vue' /* webpackChunkName: "components/header-home" */).then(c => wrapFunctional(c.default || c))
export const HeaderRanking = () => import('../../components/HeaderRanking.vue' /* webpackChunkName: "components/header-ranking" */).then(c => wrapFunctional(c.default || c))
export const HeaderRegion = () => import('../../components/HeaderRegion.vue' /* webpackChunkName: "components/header-region" */).then(c => wrapFunctional(c.default || c))
export const HerobanSearchBox = () => import('../../components/HerobanSearchBox.vue' /* webpackChunkName: "components/heroban-search-box" */).then(c => wrapFunctional(c.default || c))
export const ItemCommentButton = () => import('../../components/ItemCommentButton.vue' /* webpackChunkName: "components/item-comment-button" */).then(c => wrapFunctional(c.default || c))
export const ItemLike = () => import('../../components/ItemLike.vue' /* webpackChunkName: "components/item-like" */).then(c => wrapFunctional(c.default || c))
export const MainContainer = () => import('../../components/MainContainer.vue' /* webpackChunkName: "components/main-container" */).then(c => wrapFunctional(c.default || c))
export const MainMenu = () => import('../../components/MainMenu.vue' /* webpackChunkName: "components/main-menu" */).then(c => wrapFunctional(c.default || c))
export const MapComponent = () => import('../../components/MapComponent.vue' /* webpackChunkName: "components/map-component" */).then(c => wrapFunctional(c.default || c))
export const MapDvf = () => import('../../components/MapDvf.vue' /* webpackChunkName: "components/map-dvf" */).then(c => wrapFunctional(c.default || c))
export const ModalFilterDvf = () => import('../../components/ModalFilterDvf.vue' /* webpackChunkName: "components/modal-filter-dvf" */).then(c => wrapFunctional(c.default || c))
export const ModalForgotPassword = () => import('../../components/ModalForgotPassword.vue' /* webpackChunkName: "components/modal-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ModalLogin = () => import('../../components/ModalLogin.vue' /* webpackChunkName: "components/modal-login" */).then(c => wrapFunctional(c.default || c))
export const RecursiveComments = () => import('../../components/RecursiveComments.vue' /* webpackChunkName: "components/recursive-comments" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTop = () => import('../../components/ScrollToTop.vue' /* webpackChunkName: "components/scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTopArrow = () => import('../../components/ScrollToTopArrow.vue' /* webpackChunkName: "components/scroll-to-top-arrow" */).then(c => wrapFunctional(c.default || c))
export const TabsCity = () => import('../../components/TabsCity.vue' /* webpackChunkName: "components/tabs-city" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
