

export default {
    methods: {
        showSearchBar: function (searchVisible) {
            var self = this;
            this.searchVisible = !searchVisible;
        },
        searchBarLeave: function () {
            var self = this;
            this.searchVisible = false;
        },
        showBurger: function (isVisible) {
            var self = this;
            this.isVisible = !isVisible;
        },
        burgerLeave: function () {
            var self = this;
            this.isVisible = false;
        },
        login: function () {
            this.$nuxt.$emit("open-login-modal", { mode: 'login' });
        },
        register: function () {
            this.$nuxt.$emit("open-login-modal", { mode: 'register' });
        }
    },
    data() {
        return {
            menu: {
                classements: [],
                regions: [],
            },
            searchVisible: false,
            isVisible: false,
            isModalVisible: false,
        }
    },
    computed: {
        userMenu: function () {
            if (this.$auth.loggedIn) {
                return [
                    {
                        title: 'Profil',
                        link: '/account/profile/'
                    },
                    {
                        title: 'Favoris',
                        link: '/account/favorites/'
                    },
                    {
                        title: 'Se déconnecter'
                    },
                ]
            }
            else {
                return [
                    {
                        title: 'Se connecter'
                    },
                ]
            }
        }
    },
    async fetch() {
        this.menu = await fetch(
            `${this.$config.baseApiURL}/menu`
        ).then(res => res.json())
    },
}
