
import FacebookLogo from  "~/assets/svg/social/facebook.svg?inline";
import GoogleLogo from  "~/assets/svg/social/google.svg?inline";
import AppleLogo from  "~/assets/svg/social/apple.svg?inline";

export default {
    components: {
        FacebookLogo,
        GoogleLogo,
        AppleLogo
    },
    created () {
        if (process.browser) {
            window.addEventListener('message', this.handleMessage);
        }
    },
    destroyed () {
        if (process.browser) {
            window.removeEventListener('message', this.handleMessage);
        }
    },
    methods: {
        socialLogin(service) {
            let width = 640;
            let height = 550;
            let left = window.screen.width / 2 - (width / 2);
            let top = window.screen.height / 2 - (height / 2);

            let win = window.open(`/auth/${service}`, 'Login',
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scollbars=no,
        resizable=no, copyhistory=no, width=${width},height=${height},top=${top},left=${left}`
            );
        },
        handleMessage (event) {
             if (event.data && event.data.token) {
                 this.oAuthComplete(event.data);
             }
        },
        oAuthComplete(data) {


            this.$auth.setUserToken(true)
                .then( () => {
                    this.$auth.fetchUser().then(() => {
                        this.$emit('logged', 'true');
                    }).catch((e) => {
                        this.$auth.logout();
                    });
                });
        }
    }
}
