



export default {

    data() {
        return {
            form: {
                email : '',
            },
            errors: {},
            notSubmitted : true,
        }
    },

    methods: {
        async submit() {
            const isValid = await this.$refs.mailPasswordForm.validate();
            if (isValid) {
                this.$axios.$post(`/api/forgot-password/`, this.form)
                    .then((response) => {
                        this.notSubmitted = false;
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$refs.mailPasswordForm.setErrors(error.response.data);
                        } else {
                            alert('Une erreur est survenue, veuillez réessayer ultérieurement.');
                        }
                    })
            }
        }
    },
}
