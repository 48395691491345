

import Vue from 'vue'
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from 'vee-validate'
import * as VeeValidate from "vee-validate";

Vue.use(VeeValidate, { inject: false });
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

//import { extend,localize } from "vee-validate";

import { required, email, min, max, min_value, max_value,confirmed } from "vee-validate/dist/rules";
import fr from 'vee-validate/dist/locale/fr.json';


extend('required', {
    ...required,
    message: 'Ce champ est requis',
});
extend('email', email);
extend('min', min);
extend('max', max);
extend('confirmed', confirmed);
extend('min_value', min_value);
extend('max_value', max_value);
extend("rating", {
    computesRequired: true,
    validate: value => value > 0 && value <= 5,
    message: "Ce champ est requis"
});


localize('fr', fr);
