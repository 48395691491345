
export default {
    props: ['title', 'items', 'displayMode','icon'],
    data () {
        return {
            hover: false
        }
    },
    methods: {
        setIsVisible: function() {
            this.hover = false;
            this.$emit('update:burger-item', false)
        },
    }
}
