
    export default {
    props: ['error'],
    head() {
        return {
            title: `Page non trouvée - ${this.$config.siteTitle}`,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: `La page que vous recherchez est introuvable.`
                }
            ]
        }
    }
}
